.errorColor {
  color: #ff0000;
}
.pointer {
  cursor: pointer;
}
/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 280px) and (max-device-width: 319px) and (orientation: portrait) {
}

body,
html {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
p {
  font-size: 18px;
}
body {
  background-color: #eaebec;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

/****Login Page ****/
.login-section {
  display: flex;
  height: 100vh;
  background-size: cover;
  background-position: 100%;
}
.loginform {
  background-color: #ffffff;
  padding: 40px 70px;
  box-shadow: 0px 0px 13px rgb(0 0 0 / 5%);
  border-radius: 10px;
  margin: 0px 70px;
}
.iconsrltv {
  position: relative;
  margin-bottom: 60px;
}
.login-icon {
  position: absolute;
  top: 12px;
  left: 15px;
}
.login-icon img {
  width: 22px;
}
.iconsrltv .form-control {
  height: 55px;
  border: 1px solid #9b9b9b;
  font-size: 22px;
  color: black;
  padding-left: 50px;
}
.merchantTextField {
  padding-left: 5px !important;
}
.login-title h2 {
  text-align: center;
  color: #00a0dd;
  font-size: 36px;
  margin-bottom: 70px;
}
.iconsrltv label {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  position: absolute;
  top: -33px;
}
.loginbtn {
  background: linear-gradient(
    90deg,
    rgba(15, 66, 143, 1) 0%,
    rgba(0, 160, 221, 1) 100%
  );
  width: 100%;
  padding: 14px !important;
  font-size: 20px !important;
  color: #ffffff !important;
  border: 0px !important;
}
.loginbtn:hover {
  color: #ffffff;
  background: linear-gradient(
    90deg,
    rgba(0, 160, 221, 1) 0%,
    rgba(15, 66, 143, 1) 100%
  );
}
.loginbtn img {
  width: 18px;
  margin-left: 3px;
}
.forgotpass a {
  color: #00a0dd;
  text-decoration: underline;
}
.forgotpass {
  text-align: center;
  margin-top: 30px;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.iconsrltv .form-control:focus {
  outline: none;
  box-shadow: none;
}
.iconsrltv .form-control:focus + label {
  color: #00a0dd;
}

/**** Dashbaord *****/
.header {
  background-color: #ffffff;
  box-shadow: 0px 3px 3px #00000008;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 9999;
}
.userporfile img {
  border: 2px solid white;
  border-radius: 50%;
  width: 50px;
  box-shadow: 0px 3px 5px #00000024;
  margin-right: 9px;
}
.user-section .dropdown .dropdown-toggle {
  color: #2e2e2e;
  font-weight: 400;
  text-decoration: none;
}
.logo img {
  width: 60%;
}
.sidemenupanel {
  background-color: #f0fbff;
  width: 250px;
  position: fixed;
  height: 100%;
}
.content-wrap {
  width: calc(100% - 250px);
  margin-left: 250px;
  padding: 20px;
}
.content-body {
  display: flex;
  padding-top: 66px;
}
.logo-box {
  display: flex;
}
.logo-box .logo {
  text-align: center;

  width: 230px;
}
.searchinput {
  position: relative;
}
.searchinput span {
  position: absolute;
  top: 6px;
  left: 8px;
}
.searchinput span img {
  width: 18px;
}
.searchinput input {
  padding-left: 30px;
  background-color: #f0fbff;
  border: 1px solid #f0fbff;
}
.sidemenupanel ul {
  margin: 30px 15px;
  padding: 0px;
  list-style: none;
}
.sidemenupanel ul li a {
  padding: 15px 10px;
  display: block;
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  display: flex;
}
.sidemenupanel ul li a svg g path {
  fill: #5f5f5f;
}
.sidemenupanel ul li {
  margin-bottom: 20px;
}
.sidemenupanel ul li a img {
  margin-right: 10px;
  width: 23px;
}
.sidemenupanel ul li a.avtive {
  background: linear-gradient(
    90deg,
    rgba(15, 66, 143, 1) 0%,
    rgba(0, 160, 221, 1) 100%
  );
  color: #ffffff;
  border-radius: 5px;
  box-shadow: 1px 3px 5px #00000029;
}
.sidemenupanel ul li a:hover {
  background: linear-gradient(
    90deg,
    rgba(15, 66, 143, 1) 0%,
    rgba(0, 160, 221, 1) 100%
  );
  color: #ffffff;
  border-radius: 5px;
  box-shadow: 1px 3px 5px #00000029;
}
.sidemenupanel ul li a .w-icon {
  display: none;
}

.sidemenupanel ul li a.avtive .w-icon {
  display: block;
}
.sidemenupanel ul li a.avtive .g-icon {
  display: none;
}
.sidemenupanel ul li a.avtive .w-icon {
  display: block;
}
.sidemenupanel ul li a:hover .w-icon {
  display: block;
}
.sidemenupanel ul li a:hover .g-icon {
  display: none;
}
.page-title h2 {
  font-size: 22px;
  margin-bottom: 20px;
}

.menuimages {
  position: absolute;
  left: 0;
  bottom: 60px;
}
.menuimages img {
  width: 100%;
}
.card-box {
  /* width: 121%; */
  background-color: white;
  box-shadow: 0px 4px 8px #0000001c;
  border-radius: 5px;
  padding: 5px 0 15px 0;
}
.card-box-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #cacaca;
  margin-bottom: 20px;
}
.card-box-title h4 {
  margin-bottom: 0px;
  font-size: 20px;
}
.card-box-body {
  padding: 20px;
}
.linkbtn {
  background: linear-gradient(
    90deg,
    rgba(15, 66, 143, 1) 0%,
    rgba(0, 160, 221, 1) 100%
  );
  padding: 8px 20px;
  font-size: 16px;
  color: #ffffff;
  border: 0px;
  border-radius: 6px;
  box-shadow: 0px 1px 7px #00000030;
}
.linkbtn:hover {
  color: #ffffff;
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgba(0, 160, 221, 1) 0%,
    rgba(15, 66, 143, 1) 100%
  );
  text-decoration: none;
}
.user-section {
  display: flex;
  align-items: center;
}
.user-section ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.notification ul {
  display: flex;
}
.notification ul li a img {
  width: 22px;
  margin-right: 25px;
}
div#AddUser .card-box-body ul.nav.nav-pills li.nav-item .nav-link {
  padding: 5px 70px;
  border-bottom: 2px solid #ededed;
  transition: 0.4s ease;
}

div#AddUser .card-box-body ul.nav.nav-pills li.nav-item .nav-link.active {
  background: #f3f3f3;
  border-bottom: 2px solid #019bd9;
  border-radius: 0;
  color: #0199d7;
  font-weight: bold;
}

div#AddUser .card-box-body .tab-content .form-group > label {
  font-size: 0.8rem;
  margin-bottom: 0;
  color: #004e6f;
  font-weight: 400;
}

div#AddUser .card-box-body .btnWrap button.btn.reset {
  border: 1px solid #f00;
  color: #f00;
}

div#AddUser .card-box-body .btnWrap button.btn.next,
.getColor {
  background: #009cda;
  color: #fff;
}

div#AddUser .card-box-body .btnWrap button.btn {
  padding: 5px 30px;
  margin-left: 20px;
}

div#AddUser .card-box-body .tab-content .form-group {
  margin-bottom: 2rem;
}

.mslVars {
  width: 10px !important;
}
.marginl-3 {
  margin-left: 10px;
}
.dflex-inline {
  display: inline-flex;
}

.MuiPaper-root {
  top: 70px !important;
}
.text-sm input.form-control {
  font-size: 14px;
  padding-left: 22px;
  height: 35px;
}
.text-sm {
  padding: 20px;
}
.msl-wrp {
  width: 100% !important;
}

#merchant_details p {
  font-size: 15px;
}
#merchant_details label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: 600;
}
#merchant_setting {
  margin-top: 20px;
}

#merchant_setting p {
  font-size: 15px;
}
#merchant_setting label {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: 600;
}
.removeMarginBottom {
  margin-bottom: 0px !important;
}
.card-box-body.UserData table {
  overflow: auto;
}
div#merchant_setting .col-md-3 p,
#merchant_details .col-md-3 p {
  word-break: break-all;
}
.UserData thead th {
  white-space: nowrap;
}
.capsFirstLetter {
  text-transform: capitalize;
}
.card-box-body.UserData .table td,
.table th {
  padding: 0.67rem !important;
}
.card-box-body.transData .table td,
.table th {
  padding: 0.56rem !important;
  font-size: 1.024rem !important;
}
.UserData table td {
  word-break: break-all;
  vertical-align: middle;
}
.UserData table {
  min-width: 100% !important;
}
.lineHeight {
  line-height: 2;
}
.iconsrltvForgot {
  margin-bottom: 40px;
}
.datpiker-col {
  display: flex;
  align-items: center;
  justify-content: start;
}
.datpiker-col .react-datepicker__input-container input {
  width: 120px;
  font-size: 14px;
  padding-left: 22px;
  height: 38px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;

  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-right: 5px;
}
.datpiker-col > label {
  margin-right: 10px;
  margin-bottom: 0px;
}
.datpiker-col .react-datepicker__input-container input:focus {
  outline: none;
}


/* himanshu */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* himanshu */